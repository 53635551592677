<template>
    <v-container>
        <form slot="leftContainer" id="tagGastos" autocomplete="nope" data-vv-scope="form-2" @submit.prevent="addServicio('form-2')">
            <v-card>
                <v-card-title class="grey lighten-4">
                    <b>AGREGAR SERVICIO {{ step }} </b>
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12" sm="6" class="d-flex justify-space-around">
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-text-field v-model="servicio.details" label="Descripcion" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-text-field placeholder="0.50" required v-model="servicio.value" label="Precio del Producto" @keypress="isNumber" />
                                </v-col>
                                <v-col class="d-flex" cols="12" sm="12">
                                    <v-autocomplete
                                        :items="items"
                                        v-model="servicio.tipoServicio"
                                        item-text="text"
                                        item-value="id"
                                        label="Tipo Servicio"
                                        required
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" class="d-flex justify-center pa-2">
                            <v-text-field :rules="hourRules" v-model="servicio.duration" @input="formatHour('hourEntry')"
                            prepend-icon="mdi-clock-time-four-outline" label="Duración" placeholder="HH:mm"
                            required></v-text-field>
                        </v-col>
                                <v-col cols="12" sm="12">
                                    <v-btn type="submit" black-text large :loading="loading" dark :color="step == 1 ? 'primary' : 'orange'" block>
                                        <b>{{ step == '1' ? 'Agregar SERVICIO' : 'Actualizar' }}</b>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="6" class="d-flex justify-center pa-2">
                            <v-time-picker disabled v-model="servicio.duration" format="24hr"></v-time-picker>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </form>

        <v-card>
            <v-card-title class="grey lighten-4">
                <b>LISTA DE SERVICIOS</b>
                <v-spacer />
                <v-col cols="12" sm="3">
                    <v-text-field dense v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line hide-details />
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="12">
                        <v-data-table multi-sort :search="search" :headers="headers" :items="_listaServicios" class="elevation-1" :items-per-page="20">
                            <template v-slot:[`item.action`]="{ item }">
                                <v-icon small @click="editItem(item)" color="green">mdi-pencil</v-icon>
                                <v-icon color="primary" small @click="deleteItem(item)">mdi-delete</v-icon>
                            </template>
                            <template v-slot:[`item.category_id`]="{ item }">
                                <v-icon small color="blue">{{ category_text(item.category_id) }}</v-icon>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import { format, subDays, addDays } from 'date-fns'
import { mapState } from 'vuex'
import DefaultForm from '@/utils/defaultForm'
import { mixins } from '@/mixins/mixin.js'
export default {
    mixins: [mixins],
    data() {
        return {
            fechaFiltro: [format(subDays(new Date(), 31), 'yyyy-MM-dd'), format(addDays(new Date(), 1), 'yyyy-MM-dd')],
            menu3: false,
            loadingLista: false,
            loading: false,
            servicio: DefaultForm.formService(),
            importe: '',
            interval: null,
            items: [
                { id: 12, text: 'Privado' },
                { id: 11, text: 'Masaje' },
                { id: 10, text: 'Mixto' }
            ],
            isPlaying: false,
            search: '',
            step: 1,
            headers: [
                { text: '', value: 'action', sortable: false },
                { text: 'Descripción', value: 'description' },
                { text: 'Categoria', value: 'category_id' },
                { text: 'Precio', value: 'sale_unit_price' },
                { text: 'Horas', value: 'hours' }
            ]
        }
    },
    async created() {
        await this.getLista()
    },
    computed: {
        ...mapState('gastos', ['listaServicios']),

        animationDuration() {
            return `${60 / this.servicio.duration}s`
        },
        _listaServicios() {
            let newLista = []
            console.log('listaServicios', this.listaServicios)
            if (this.listaServicios.length > 0) {
                this.listaServicios.map((x) => {
                    if (x.status == 1) {
                        newLista.push({
                            ...x
                        })
                    }
                })
            }
            //console.log('newLista', newLista)
            return newLista
        }
    },
    methods: {
        formatHour(field) {
            // Formatea automáticamente la hora al formato deseado
            if (this.servicio.duration.length === 2 && !this.servicio.duration.includes(':')) {
                this.servicio.duration += ':';
            }
        },
        async deleteItem(item) {
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text: 'Eliminando Servicio'
            })
            await this.$store.dispatch('gastos/deleteServicios', item)
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        },
        category_text(payload) {
            if (payload === 12) {
                return 'Privado'
            } else if (payload === 11) {
                return 'Masaje'
            } else if (payload === 10) {
                return 'Mixto'
            } else {
                return '-'
            }
        },
        decrement() {
            this.servicio.duration--
        },
        increment() {
            this.servicio.duration++
        },
        toggle() {
            this.isPlaying = !this.isPlaying
        },
        async getLista() {
            this.loadingLista = true
            await this.$store.dispatch('gastos/getServicios')
            //console.log('checkout')
            this.loadingLista = false
            this.menu3 = false
        },
        async addServicio() {
            //console.log('servicio', this.servicio)
            this.loading = true
            if (this.step === 1) {
                await this.$store.dispatch('gastos/postServicios', this.servicio)
            } else {
                await this.$store.dispatch('gastos/patchServicios', this.servicio)
            }
            await this.getLista()

            this.servicio.details = null
            this.servicio.value = null
            this.servicio.duration = null
            this.servicio.tipoServicio = null
            this.servicio.id = null
            this.loading = false
            this.step = '1'
        },
        async editItem(item) {
            const _time = item.hours.split('H')[0]
            // console.log('time',time)
            // const parts = time.split(':')
            // const _time = parseInt(parts[0]) * 60 + parseInt(parts[1])
            // //console.log('_time', _time)
            this.servicio.details = item.description
            this.servicio.value = item.sale_unit_price
            this.servicio.duration = _time
            this.servicio.tipoServicio = item.category_id
            this.servicio.id = item.id
            //console.log('this.service', this.servicio)
            this.step = 2
            window.scrollTo(0, 0)
        }
    }
}
</script>
<style>
@keyframes metronome-example {
    from {
        transform: scale(0.5);
    }

    to {
        transform: scale(1);
    }
}

.v-avatar--metronome {
    animation-name: metronome-example;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
</style>
